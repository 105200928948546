import { useState, useEffect } from "react";
import Linkify from 'react-linkify';

const query = `
{
  projectCollection{
    items{
      title
      url
    }
  }
}
`;


function Projects(){

    const [projects, setProjects] = useState(null);

    useEffect(() => {
      window
        .fetch(`https://graphql.contentful.com/content/v1/spaces/l0049fl7s10o/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer Idjl1Hk8zVhPPL_xkTXsawINe2VA7Ep0qq9QatVovZ8",
          },
          body: JSON.stringify({ query }),
        })
        .then((response) => response.json())
        .then(({ data, errors }) => {
          if (errors) {
            console.error(errors);
          }
  
          setProjects(data.projectCollection.items);
        });
    }, []);
  
    if (!projects) {
      return "Loading...";
    }

    

    return(
         <section id="Projects">
             <h2>Latest Projects</h2>
        {projects &&
          projects.map((item, index) => (
              <span key={index}>
                <span>
                  <h3>{item.title}</h3>
                 <Linkify><code>{item.url}</code></Linkify>
                </span>
              </span>
          ))}
      </section>
    )
}

export default Projects