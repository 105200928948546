
import {Helmet} from "react-helmet";
import "./App.css";
import Header from "./components/Header";
import Introduction from "./components/Introduction";
import Projects from "./components/Projects";


function App() {
 
  // render the fetched Contentful data
  return (
    <>
     <Helmet>
                <meta charSet="utf-8" />
                <title>Hasan Altun</title>
                 <meta name="description" content="I’m developing professional ecommerce stores on the Shopify platform for a wide range of mostly Swedish and other European retail brands." />
                <link rel="canonical" href="https://hasanaltun.co" />
            </Helmet>
    <Header/>
    <Introduction/>
    <Projects/>
    </>
  );
}

export default App;

